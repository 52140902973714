import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

// Components
import Header from "../components/global/header";
import "../components/styles/menu.css";

const Container = styled.div`
  max-width: 700px;

  padding: 50px 30px;

  color: #fff;

  & h1 {
    font-weight: 600;
  }

  & p {
    font-weight: normal;
  }

  @media (max-width: 767px) {
    padding: 20px;
  }
`;

const SectionTitle = styled.div`
  padding: 30px;

  @media (max-width: 767px) {
    padding: 20px;
  }

  & img {
    height: 55px;
    width: auto;

    @media (max-width: 500px) {
      height: 30px;
    }
  }
`;

const PrivacyPolicy = ({ data }) => (
  <>
    <Header />
    <SectionTitle color={"#fff"}>
      <Link to={`/`}>
        <img src={data.prismicHomepage.data.svg_text.url} alt={`Spark Icon`} />
      </Link>
    </SectionTitle>

    <Container>
      <div
        dangerouslySetInnerHTML={{
          __html: data.prismicPrivacyPolicy.data.title.html,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: data.prismicPrivacyPolicy.data.text.html,
        }}
      />
    </Container>
  </>
);

export const query = graphql`
  {
    prismicHomepage {
      data {
        svg_text {
          url
        }
      }
    }
    prismicPrivacyPolicy {
      data {
        text {
          html
        }
        title {
          html
        }
      }
    }
  }
`;

export default PrivacyPolicy;
